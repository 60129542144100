<template>
  <div v-if="offer">
    <div class="standard noprint" v-if="!isPrintLayout">
      <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    </div>
    <div :class="{ 'flex-container': !isPrintLayout }" ref="document">
      <div class="document enable-print flex-document">
        <OfferPrintFirstPage :offer="offer" />
        <OfferPrintSecondPage :offer="offer" />
        <OfferPrintThirdPage :offer="offer" />
      </div>

      <Widget
        v-if="!isPrintLayout"
        title="Angebot bearbeiten"
        class="flex-toolbar document-sidebar editor-widget"
      >
        <legend>Vorlagen</legend>
        <div>
          <multiselect
            placeholder="Vorlage wählen"
            v-model="selectedSystemPosition"
            @select="onSelectBoilerplate"
            :options="offerBoilerplates"
            :option-height="200"
            :show-labels="false"
            track-by="title"
            label="title"
          >
          </multiselect>
        </div>

        <div class="mt-lg">
          <p>
            <router-link :to="{ name: 'OffersPage' }">
              <b-button @click.prevent="onCancel" class="mr-xs btn btn-sm btn-default mb-xs">{{
                $t('cancel')
              }}</b-button>
            </router-link>
            <b-button
              :disabled="buttonsDisabled"
              @click.prevent="saveOffer"
              type="submit"
              class="btn btn-success btn-sm mr-xs mb-xs"
              >{{ $t('save') }}</b-button
            >
          </p>
        </div>
      </Widget>

      <Widget
        v-if="!isPrintLayout && offer.comments && offer.comments.length > 0"
        title="Kommentare"
        class="flex-toolbar document-sidebar comments-widget"
      >
        <ul class="post-comments mt-sm">
          <li v-for="comment in offer.comments" :key="comment.id">
            <span class="thumb-xs avatar pull-left mr-sm">
              <img
                v-if="comment.user.avatar"
                class="rounded-circle"
                :src="comment.user.avatar.path"
                alt=""
              />
              <img v-else class="rounded-circle" src="../../assets/avatar2.png" alt="" />
            </span>
            <h6 class="author fs-sm fw-semi-bold">
              {{ comment.user.firstname }} {{ comment.user.lastname }}
              <small>{{ $fromNow(comment.createdAt) }} {{ $datetime(comment.createdAt) }}</small>
            </h6>
            <p>{{ comment.content }}</p>
          </li>
        </ul>
      </Widget>
    </div>
  </div>
  <div v-else>{{ $t('offer') }} {{ $t('not_found') }}</div>
</template>

<script>
import Widget from '@/components/Widget/Widget';

import Multiselect from 'vue-multiselect';
import { mapGetters, mapActions, mapState } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs';
import OfferPrintFirstPage from '@/components/Print/OfferPrintFirstPage';
import OfferPrintSecondPage from '@/components/Print/OfferPrintSecondPage';
import OfferPrintThirdPage from '@/components/Print/OfferPrintThirdPage';

export default {
  name: 'OfferEditPage',
  components: {
    Breadcrumbs,
    Multiselect,
    Widget,
    OfferPrintFirstPage,
    OfferPrintSecondPage,
    OfferPrintThirdPage,
  },
  props: {
    offerNumber: {
      type: String,
      required: false,
      default: null,
    },
    layoutType: {
      default: 'web',
    },
  },
  data() {
    return {
      activeInput: false,
      buttonsDisabled: false,
      value: [],
      selectedSystemPosition: null,
    };
  },
  computed: {
    ...mapGetters([
      'getOffer',
      'getOfferByNumber',
      // 'getUsers',
      'getOfferBoilerplates',
      // 'getOfferBoilerplateTypes',
      // 'getGroupedOfferBoilerplates',
      // 'getUsersGroupedByCompany',
      'getOfferPositionCount',
    ]),
    isPrintLayout() {
      return this.layoutType === 'print';
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: 'Angebote', route: { name: 'OffersPage' } },
        { name: this.offer.number !== '_new' ? this.$t('edit') : this.$t('new') },
      ];
    },
    positionCount: {
      get() {
        const count = this.getOfferPositionCount(this.offer.id);
        return count;
      },
    },
    offer: {
      get() {
        if (this.offerNumber) {
          return this.getOfferByNumber(this.offerNumber);
        }
        this.initOffer();
        return this.getOffer('_new');
      },
    },
    users() {
      let users = [];

      this.getUsers.map((user) =>
        users.push({
          label: user.firstname + ' ' + user.lastname,
          value: user,
        })
      );
      return users;
    },
    offerBoilerplates() {
      return this.getOfferBoilerplates;
      //   const groupedOfferBoilerplates = [];
      //   this.getOfferBoilerplateTypes.map((type) =>
      //     groupedOfferBoilerplates.push({
      //       type: type,
      //       translatedType: this.$t(type),
      //       boilerplates: [],
      //     })
      //   );
      //   this.getGroupedOfferBoilerplates.map((boilerplate) => {
      //     groupedOfferBoilerplates.map((group) => {
      //       if (group.type === boilerplate.type) {
      //         group.boilerplates.push(boilerplate);
      //       }
      //     });
      //   });
      //   return groupedOfferBoilerplates;
    },
  },
  methods: {
    ...mapActions([
      'fetchOfferByNumber',
      'updateOffer',
      'createOffer',
      'initOffer',
      // 'localUpdate',
      // 'addDefaultPosition',
      // 'fetchUsers',
      // 'calculateOfferTotalCost',
      // 'fetchOfferBoilerplateTypes',
      'fetchOfferBoilerplates',
      'offerCleanup',
      'offerAddSystemPosition',
      // 'offerRemovePosition',
      // 'messageOfferToCustomer',
      // 'offerPositionCount',
    ]),

    /**
     * iterate over all document children
     * summarize height of children
     * if height > (a4 - footer) -> break page
     */
    customLabel({ firstname, lastname }) {
      return `${firstname} – ${firstname}`;
    },
    async saveOffer() {
      try {
        if (this.offer.id && this.offer.id != '_new') {
          await this.updateOffer(this.offer);
        } else {
          const offerNumber = await this.createOffer(this.offer);
          this.$router.push({
            name: 'OfferEditPage',
            params: { offerNumber: offerNumber },
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    // editingStateColor($state) {
    //   return offerEditingStateColor($state);
    // },
    // customerStateColor($state) {
    //   return offerCustomerStateColor($state);
    // },
    /**
     * Trigger update when objects in subarrays are changed
     */
    updateLocalStore(position) {
      this.localUpdate(this.offer);
    },
    addPosition() {
      console.log('addPosition');
      this.addDefaultPosition(this.offer);
    },
    onSelectRecipient(recipient) {
      this.$set(
        this.offer,
        'address',
        recipient.company.name +
          '<br />' +
          recipient.firstname +
          ' ' +
          recipient.lastname +
          recipient.company.address
      );
    },
    onSelectBoilerplate(boilerplate) {
      console.log(boilerplate);
      if (boilerplate.type === 'OFFER_SYSTEM_POSITION') {
        this.selectedSystemPosition = boilerplate.systemPosition;
        this.offerAddSystemPosition({
          offer: this.offer,
          systemPosition: boilerplate.systemPosition,
        });
        // this.calculateOfferTotalCost(this.offer);
      }
    },
    onDocumentPositionChanged(value) {
      this.calculateOfferTotalCost(this.offer);
    },
    onDocumentPositionTrashed(positionIndex) {
      this.offerRemovePosition({
        offer: this.offer,
        positionIndex: positionIndex,
      });
      this.calculateOfferTotalCost(this.offer);
    },
    onCancel() {
      this.offerCleanup();
      this.$router.push({ name: 'OffersPage' }).catch((e) => {
        console.log(e);
      });
    },
    /**
     * Save offer and send email to customer
     */
    async onSendEmailToRecipient() {
      await this.saveOffer();
      await this.messageOfferToCustomer(this.offer);
    },
  },
  async mounted() {
    // console.log(this.$refs["a4"].scrollHeight);
    if (!this.offer && this.offerNumber) {
      // get offer from grapqhl when not existing in store
      await this.fetchOfferByNumber(this.offerNumber);
      // if (this.offer) {
      //   this.calculateOfferTotalCost(this.offer);
      // }
    }
    // await this.fetchUsers();
    // await this.fetchBoilerplateTypes();
    await this.fetchOfferBoilerplates();

    // if (this.offer && this.offer.systemPositions.length > 0) {
    //   this.selectedSystemPosition = this.offer.systemPositions[0];
    // }
  },
};
</script>

<style scoped lang="scss">
div.flex-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 500px 1fr auto;
}
.flex-document {
  flex-grow: 0; /* do not grow   - initial value: 0 */
  flex-shrink: 0; /* do not shrink - initial value: 1 */
  flex-basis: auto; /* width/height  - initial value: auto */
}
.flex-toolbar {
  margin-left: 1.2em;
  max-width: 400px;
  min-width: 350px;
  // height: 600px;
}
.comments-widget {
  grid-column: 2 / 4;
  grid-row: 2 / 4;
}
.editor-widget {
  grid-column: 2 / 4;
  grid-row: 1 / 2;
}
.document {
  grid-row: 1 / -1;
}

/** Grid layout for document positions */
.grid-document-position {
  padding-right: 1cm;
  display: grid;
  grid-template-columns: 4fr 3fr 2fr 2fr;
  grid-template-rows: auto;
  grid-column-gap: 5px;
}

.document-position-footer .position-total-price {
  grid-column: 3 / 5;
}

/** Document Styling */
.document {
  color: black;
  font-size: 10pt;
}

::v-deep .document p {
  margin: 0 !important;
  margin-bottom: 0;
}

.document-positions {
  margin: 2em 0;
}
.document-position-header {
  border-bottom: 1px solid black;
}
.document-position-footer {
  font-weight: bold;
  margin: 2em 0;
}

/** Document Sidebar */
.document-sidebar {
  font-size: 0.9em;
  // height: 100%;
  display: block;
}
.document-sidebar h3 {
  font-size: 1.2em;
}

/** print */
@media print {
  .flex-container {
    display: block;
  }

  .flex-document {
    margin: 0;
  }

  .A4 {
    width: 100%;
    box-shadow: none;
    margin: 0;
    font-size: 12pt;
  }

  .noprint {
    display: none;
  }

  // .enable-print {
  //   display: block;
  // }
}
</style>
